/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line
import React, { useContext } from "react";
import { Panel, Link, Button } from "@xometry/xometry_loft";
import { jsx } from "@emotion/react";
/** @jsx jsx */
import { track } from "../../utils/analytics";

import {
  wrapperDiv,
  buttonCss,
  headerBar,
  imageWrapper,
  newBadge,
  headerTitle as headerTitleCss,
  headerSubtitle as headerSubtitleCss,
  cardBody as cardBodyCss,
  titleContent,
} from "./MarketingCard.style";

type Maybe<T> = T | null;

export interface PrismicContent {
  headerTitle: string;
  headerSubtitle?: Maybe<string>;
  newLabel: boolean;
  headerLinkText?: Maybe<string>;
  headerImage?: {
    url: Maybe<string>;
    alt?: Maybe<string>;
  };
  headerLinkUrl?: Maybe<string>;
  cardBody?: Maybe<string>;
  bodyImage?: {
    url?: Maybe<string>;
    alt?: Maybe<string>;
  };
  ctaText?: Maybe<string>;
  ctaUrl?: Maybe<string>;
}

const NewBadge: React.FC<boolean> = isNew => {
  return isNew ? <div css={newBadge}>New</div> : null;
};

interface HeaderLinkContentProps {
  headerImage?: {
    url: Maybe<string>;
    alt?: Maybe<string>;
  };
  headerLinkText?: Maybe<string>;
}

const HeaderLinkContent: React.FC<HeaderLinkContentProps> = (
  props: HeaderLinkContentProps
) => {
  const { headerImage, headerLinkText } = props;
  if (headerImage?.url) {
    return <img src={headerImage.url} alt={headerImage.alt || "header"} />;
  }
  if (headerLinkText) {
    return <div>{headerLinkText}</div>;
  }
  return null;
};

interface HeaderLinkProps {
  headerTitle?: Maybe<string>;
  headerImage?: {
    url: Maybe<string>;
    alt?: Maybe<string>;
  };
  headerLinkText?: Maybe<string>;
  headerLinkUrl?: Maybe<string>;
}

const HeaderLink: React.FC<HeaderLinkProps> = (props: HeaderLinkProps) => {
  const {
    headerTitle = "",
    headerImage,
    headerLinkText,
    headerLinkUrl,
  } = props;
  if (!headerImage && !headerLinkText) {
    return null;
  }

  const segmentEventDetail = headerImage ? "header image" : headerLinkText;
  const segmentEventName = `${headerTitle} ${segmentEventDetail}`;
  if (headerLinkUrl) {
    return (
      <div>
        <Link
          onClick={(): boolean => {
            track(segmentEventName);
            return true;
          }}
          target="_blank"
          href={headerLinkUrl}
        >
          {HeaderLinkContent({ headerImage, headerLinkText })}
        </Link>
      </div>
    );
  }
  return <div>{HeaderLinkContent({ headerImage, headerLinkText })}</div>;
};

interface BodyImageProps {
  bodyImage?: {
    url?: Maybe<string>;
    alt?: Maybe<string>;
  };
}

const BodyImage: React.FC<BodyImageProps> = (props: BodyImageProps) => {
  const { bodyImage } = props;

  if (!bodyImage?.url) return null;

  return (
    <div css={imageWrapper}>
      <img src={bodyImage.url} alt={bodyImage.alt || "body"} />
    </div>
  );
};
interface CTAButtonProps {
  ctaText?: Maybe<string>;
  ctaUrl?: Maybe<string>;
}

const CTAButton: React.FC<CTAButtonProps> = (props: CTAButtonProps) => {
  const { ctaText, ctaUrl } = props;

  if (!ctaText || !ctaUrl) return null;

  return (
    <Button
      data-testid="cta_button"
      css={buttonCss}
      onClick={(): boolean => {
        track(ctaText);
        window.location.href = ctaUrl;
        return true;
      }}
    >
      {ctaText}
    </Button>
  );
};

interface MarketingCardProps {
  content: PrismicContent;
}

const MarketingCard: React.FC<MarketingCardProps> = (
  props: MarketingCardProps
) => {
  const { content } = props;
  const {
    newLabel,
    headerTitle,
    headerSubtitle,
    headerImage,
    headerLinkText,
    headerLinkUrl,
    bodyImage,
    cardBody,
    ctaText,
    ctaUrl,
  } = content;
  return (
    <Panel kind="outlineWhite" css={wrapperDiv}>
      <div css={headerBar}>
        <div>
          {NewBadge(newLabel)}
          <div css={titleContent}>
            <div css={headerTitleCss}>{headerTitle}</div>
            <div css={headerSubtitleCss}>{headerSubtitle}</div>
          </div>
        </div>
        {HeaderLink({
          headerTitle,
          headerImage,
          headerLinkText,
          headerLinkUrl,
        })}
      </div>
      <div
        css={cardBodyCss}
        // We have complete control over the content in Prismic, and it's being rendered using Prismic's own
        // library which (presumably) also does sanitization. So this *should* be perfectly safe.
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: cardBody || "" }}
      />
      {BodyImage({ bodyImage })}
      {CTAButton({ ctaText, ctaUrl })}
    </Panel>
  );
};

export default MarketingCard;
