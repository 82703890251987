import React, { Suspense, ComponentType, useState, useMemo, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button, Spinner, Panel } from "@xometry/xometry_loft";
import { loadRemoteComponent } from "../../../utils/lazyLoad";
import {
  loadingStyles,
  centeredContent,
  wrapper,
  quoteMessageBase,
  quoteMessageText,
  quoteMessageLink,
} from "./RecentQuotes.style";
import { origins, publicUrl } from "../../../utils/constants";
import { FilterData } from "../../../utils/useBrowserHistory";

const RecentQuotesReload = ({ handleReload }: { handleReload: () => void }) => (
  <div css={quoteMessageBase}>
    <img
      width="228"
      height="165"
      src={`${publicUrl}/assets/images/spot-internal-server-error.svg`}
      alt="Server Error Illustration"
    />
    <div css={quoteMessageText}>
      We&apos;re having trouble loading your quotes.
    </div>
    <Button css={quoteMessageLink} kind="text-blue" onClick={handleReload}>
      Click to try again
    </Button>
  </div>
);

// eslint-disable-next-line no-shadow
enum QuoteColumn {
  QUOTE_ID = "Quote ID",
  SUBTOTAL = "Subtotal",
  LAST_UPDATED = "Last Updated",
  LEAD_TIME = "Lead Time",
  PRODUCTION_SPEED = "Production Speed",
  STATUS = "Status",
  OWNER = "Owner",
}

type RemoteRecentQuotesProps = {
  tableConfig?: {
    quoteColumns?: QuoteColumn[];
    quotesPerPage?: number;
    showViewAllQuotesLink?: boolean;
  };
  queryParams?: FilterData;
  updateUrlWithFilterData?: (newFilterData: FilterData) => void;
  setErrorMessage?: (b: boolean) => void;
  isTeamView?: boolean;
};

const getLazyRecentQuotesComponent = () =>
  lazy(() => {
    type ModuleType = { default: ComponentType<RemoteRecentQuotesProps> };
    return loadRemoteComponent(
      `${origins.home}/quoting/quote/microcomponents/quote-table.mjs`
    ) as Promise<ModuleType>;
  });

const RecentQuotesLoading = () => (
  <Panel kind="primary" css={[wrapper, loadingStyles, centeredContent]}>
    <Spinner />
  </Panel>
);

const RecentQuotesWrapper = ({
  reload,
  children,
}: {
  reload: boolean;
  children: any;
}) => (reload ? <RecentQuotesLoading /> : <div>{children}</div>);

export type RecentQuotesProps = {
  onError: (err: Error) => void;
  queryParams?: FilterData;
  updateUrlWithFilterData?: (newFilterData: FilterData) => void;
  setShareError: (b: boolean) => void;
  isTeamView: boolean;
};

const RecentQuotes = ({
  onError,
  setShareError,
  isTeamView,
}: RecentQuotesProps) => {
  const [forceReload, setForceReload] = useState(false);

  const RemoteRecentQuotesComponent = useMemo(getLazyRecentQuotesComponent, [
    forceReload,
  ]);

  const tableConfig = {
    quotesPerPage: 3,
    showViewAllQuotesLink: true,
    quoteColumns: [
      QuoteColumn.QUOTE_ID,
      QuoteColumn.SUBTOTAL,
      QuoteColumn.LAST_UPDATED,
      QuoteColumn.STATUS,
      QuoteColumn.OWNER,
    ],
  };

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <RecentQuotesReload
          handleReload={() => {
            setForceReload(true);
            resetErrorBoundary?.();
            setTimeout(() => {
              setForceReload(false);
            }, 1000);
          }}
        />
      )}
      onError={err => {
        onError(err);
      }}
    >
      <RecentQuotesWrapper reload={forceReload}>
        <Suspense fallback={<RecentQuotesLoading />}>
          <RemoteRecentQuotesComponent
            tableConfig={tableConfig}
            setErrorMessage={setShareError}
            isTeamView={isTeamView}
          />
        </Suspense>
      </RecentQuotesWrapper>
    </ErrorBoundary>
  );
};

export default RecentQuotes;
