// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import {
  punchoutInQueryString,
  punchoutSessionInQueryString,
} from "../../utils/helpers";

export const PunchOutContext = React.createContext({
  punchOut: false,
  punchoutSessionId: "",
});

export default function PunchOutContextStore({
  children,
}: {
  children: React.ReactNode;
}) {
  let initialPunchoutValue = false;
  let initialPunchoutSession = "";
  try {
    initialPunchoutValue =
      !!sessionStorage.getItem("punch_out_flag") &&
      !!JSON.parse(sessionStorage.getItem("punch_out_flag") as string);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    initialPunchoutValue = false;
  }
  try {
    initialPunchoutSession = sessionStorage.getItem("punchout_session_id")
      ? JSON.parse(sessionStorage.getItem("punchout_session_id") as string)
      : "";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    initialPunchoutSession = "";
  }
  const [punchOut, setPunchOut] = useState(initialPunchoutValue);
  const [punchoutSessionId, setPunchoutSessionId] = useState(
    initialPunchoutSession
  );

  useEffect(() => {
    const punchOutFlagQueryString = punchoutInQueryString();
    const punchoutSessionQueryString = punchoutSessionInQueryString();
    const punchOutFlag = punchOutFlagQueryString || punchOut;
    const localPunchoutSessionId =
      punchoutSessionQueryString || punchoutSessionId;
    window.sessionStorage.setItem(
      "punch_out_flag",
      JSON.stringify(punchOutFlag)
    );
    window.sessionStorage.setItem(
      "punchout_session_id",
      JSON.stringify(localPunchoutSessionId)
    );
    setPunchOut(punchOutFlag);
    setPunchoutSessionId(localPunchoutSessionId as string);
  }, [punchOut, punchoutSessionId]);

  const value = {
    punchOut,
    punchoutSessionId,
  };
  return (
    <PunchOutContext.Provider value={value}>
      {children}
    </PunchOutContext.Provider>
  );
}
