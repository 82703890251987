// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line
import React from "react";
import { Button, Link, Panel } from "@xometry/xometry_loft";
import { jsx } from "@emotion/react";
import {
  bannerBase,
  bodyTextWrapper,
  buttonCss,
  ctaButtonWapper,
} from "./NotificationBanner.style";
// todo: consolidate segment and split tracking
import { track } from "../../utils/analytics";

/** @jsx jsx */

export type BannerLink = {
  text: string;
  link: string;
};

export type NotificationBannerProps = {
  name: string;
  title: string;
  bodyText: string | null | undefined;
  ctaButton: BannerLink | null | undefined;
  learnMoreLink: BannerLink | null | undefined;
  icon: string | null | undefined;
};

const NotificationBanner = (props: NotificationBannerProps) => {
  const { name, bodyText, title, learnMoreLink, ctaButton, icon } = props;
  const whitespace = " ";
  const getBodyText = () => {
    if (!bodyText) {
      return null;
    }
    if (!learnMoreLink) {
      return <p>{bodyText}</p>;
    }
    return (
      <p>
        {bodyText}
        {whitespace}
        <Link
          onClick={() => {
            const ctaName = `${name}_learn_more`;
            track(ctaName);
            return true;
          }}
          target="_blank"
          href={learnMoreLink?.link}
        >
          {learnMoreLink?.text}
        </Link>
      </p>
    );
  };
  const getCTA = () => {
    if (!ctaButton) {
      return null;
    }
    return (
      <div css={ctaButtonWapper}>
        <Button
          css={buttonCss}
          onClick={() => {
            const link = ctaButton?.link || "#";
            const ctaName = `${name}_cta_button`;
            track(ctaName);
            if (/^#/.test(link)) {
              window.location.hash = link;
            } else {
              window.open(link);
            }
            return true;
          }}
        >
          {ctaButton?.text}
        </Button>
      </div>
    );
  };

  return (
    <Panel kind="primary" css={bannerBase}>
      <div css={bodyTextWrapper}>
        <div>
          {icon ? <img alt={`${name} banner icon`} src={icon} /> : null}
        </div>
        <div>
          <h2>{title}</h2>
          {getBodyText()}
        </div>
      </div>
      {getCTA()}
    </Panel>
  );
};

export default NotificationBanner;
