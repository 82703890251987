import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

const wrapper = css`
  margin-bottom: 32px;
`;

const loadingStyles = css`
  min-height: 230px;
`;

const centeredContent = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const toolsMessageBase = css`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 12px;
  padding: 40px 24px 24px 24px;
`;

const toolsMessageText = css`
  color: ${theme.colors.darkBlues[100]};
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
`;

const toolsMessageLink = css`
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
`;

export {
  wrapper,
  centeredContent,
  loadingStyles,
  toolsMessageBase,
  toolsMessageText,
  toolsMessageLink,
};
