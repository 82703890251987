/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from "react";
import { unmountComponentAtNode, render } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import constants, { origins } from "../../utils/constants";
import { addImportDependencies } from "../../utils/lazyLoad";

export interface FormStepperContainerProps {
  config: {
    publicUrl: string;
    routerLocation: string;
    baseDomain: string;
    mockGraphqlServer: boolean;
    origins: {
      get: string;
      www: string;
      auth: string;
      partFilesService: string;
      graphql: string;
    };
    service: string;
  };
}

function ErrorFallback({ error }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
}

const customerQuoteRequestProps: FormStepperContainerProps = {
  config: {
    publicUrl: "/",
    routerLocation: "/customer-quote-request-modal",
    service: "marketing-frontend",
    baseDomain: origins.domain,
    mockGraphqlServer: constants.isDev,
    origins: {
      partFilesService: origins.pfs,
      www: origins.home,
      auth: origins.auth,
      get: origins.get,
      graphql: `${origins.home}/api/graphql/`,
    },
  },
};

let FormStepperComponentType: React.FC<FormStepperContainerProps>;

const CustomerQuoteRequestComponent = React.lazy(() => {
  const isSSR = typeof window === "undefined";

  if (!isSSR) {
    addImportDependencies();
  }
  window.marketplaceFrontendUrl = `${origins.home}/mf-static/`;

  type Module = { default: typeof FormStepperComponentType };
  return window.dynamicImport(
    `${origins.home}/mf-static/component.mjs`
  ) as Promise<Module>;
});

const CustomRFQMicroComponent: React.FC = (): JSX.Element => {
  // populate publicUrl dynamically
  customerQuoteRequestProps.config.publicUrl = window.location.pathname;
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, errorInfo) => {
        console.error(`${error}: CustomerQuoteRequestComponent`);
        console.error(errorInfo);
      }}
    >
      <Suspense fallback={<></>}>
        <CustomerQuoteRequestComponent {...customerQuoteRequestProps} />
      </Suspense>
    </ErrorBoundary>
  );
};

export const openCustomerRfqModal = () => {
  const rfqContainer = document.getElementById("rfqContainer");
  if (rfqContainer) {
    unmountComponentAtNode(rfqContainer);
    render(<CustomRFQMicroComponent />, rfqContainer);
  }
};
