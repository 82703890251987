import React, { Suspense, ComponentType, useState, useMemo, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button, Spinner, Panel } from "@xometry/xometry_loft";
import { loadRemoteComponent } from "../../../utils/lazyLoad";
import {
  loadingStyles,
  centeredContent,
  wrapper,
  toolsMessageBase,
  toolsMessageText,
  toolsMessageLink,
} from "./RecentTools.style";
import { origins, publicUrl } from "../../../utils/constants";

const RecentToolsReload = ({ handleReload }: { handleReload: () => void }) => (
  <div css={toolsMessageBase}>
    <img
      width="228"
      height="165"
      src={`${publicUrl}/assets/images/spot-internal-server-error.svg`}
      alt="Server Error Illustration"
    />
    <div css={toolsMessageText}>
      We&apos;re having trouble loading your tools.
    </div>
    <Button css={toolsMessageLink} kind="text-blue" onClick={handleReload}>
      Click to try again
    </Button>
  </div>
);

type RemoteRecentToolsProps = {
  pageSize?: number;
};

const getLazyRecentToolsComponent = () =>
  lazy(() => {
    type ModuleType = { default: ComponentType<RemoteRecentToolsProps> };
    return loadRemoteComponent(
      `${origins.home}/quoting/quote/microcomponents/tools-table.mjs`
    ) as Promise<ModuleType>;
  });

const RecentToolsLoading = () => (
  <Panel kind="primary" css={[wrapper, loadingStyles, centeredContent]}>
    <Spinner />
  </Panel>
);

const RecentToolsWrapper = ({
  reload,
  children,
}: {
  reload: boolean;
  children: any;
}) => (reload ? <RecentToolsLoading /> : <div>{children}</div>);

export type RecentToolsProps = {
  onError: (err: Error) => void;
};

const RecentTools = ({ onError }: RecentToolsProps) => {
  const [forceReload, setForceReload] = useState(false);

  const RemoteRecentToolsComponent = useMemo(getLazyRecentToolsComponent, [
    forceReload,
  ]);

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <RecentToolsReload
          handleReload={() => {
            setForceReload(true);
            resetErrorBoundary?.();
            setTimeout(() => {
              setForceReload(false);
            }, 1000);
          }}
        />
      )}
      onError={err => {
        console.log(err, "err");
        onError(err);
      }}
    >
      <RecentToolsWrapper reload={forceReload}>
        <Suspense fallback={<RecentToolsLoading />}>
          <RemoteRecentToolsComponent pageSize={3} />
        </Suspense>
      </RecentToolsWrapper>
    </ErrorBoundary>
  );
};

export default RecentTools;
