/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from "react";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import PrismicDom from "prismic-dom";
import {
  OperationVariables,
  QueryOptions,
  ApolloQueryResult,
} from "@apollo/client";

import {
  GetCardsQuery,
  PersonalizedStartPageMarketingCards as Node,
  GetCardsQueryResult,
} from "../../schema/generated/prismic-schema";

import MarketingCard, { PrismicContent } from "./MarketingCard";

type Cards = Node["body"];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type PrismicQuery = <T, TVariables = OperationVariables>(
  options: QueryOptions<TVariables>
) => Promise<ApolloQueryResult<GetCardsQueryResult>>;

interface PrismicClient {
  query: PrismicQuery;
}

type User = {
  emailAddress: string | null;
};

type Props = {
  prismicClient: PrismicClient;
  loggedInUser: User;
};

const MarketingCards: React.FC<Props> = ({
  prismicClient,
  loggedInUser,
}: Props) => {
  const [cards, setCards] = useState<Cards>();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async (): Promise<void> => {
      const response = await prismicClient.query({
        query: GetCardsQuery,
      });
      if (isMounted) {
        setCards(
          response?.data?.allPersonalized_start_page_marketing_cardss
            ?.edges?.[0]?.node?.body as Cards
        );
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [prismicClient]); // Only update if the prismicClient changes (should only fetch data once on render)

  const renderCards = (): Array<JSX.Element> | null => {
    const components: Array<JSX.Element> | undefined = cards?.reduce(
      (acc: Array<JSX.Element>, card, idx: number) => {
        const cardData = card.primary;
        let props;
        if (cardData?.header_title) {
          props = {
            headerTitle: cardData?.header_title,
            headerSubtitle: cardData?.header_subtitle,
            headerLinkText: cardData?.header_link_text,
            headerLinkUrl: cardData.header_link_url
              ? PrismicDom.Link.url(cardData?.header_link_url)
              : undefined,
            headerImage: cardData?.header_image
              ? cardData?.header_image
              : undefined,
            cardBody: cardData?.card_body
              ? PrismicDom.RichText.asHtml(cardData?.card_body)
              : undefined,
            bodyImage: cardData?.body_image,
            ctaText: cardData?.cta_text,
            ctaUrl: cardData?.cta_url
              ? PrismicDom.Link.url(cardData?.cta_url)
              : undefined,
            newLabel: cardData?.new_label,
          };
        }

        // Prismic UI doesn't provide for limited number of ads, so keep it to 3
        // Card must have default_content boolean marked as true
        /* eslint-disable react/prop-types */
        if (props && acc.length < 3 && cardData?.default_content) {
          const cardElement = (
            <MarketingCard
              content={props as PrismicContent}
              key={`marketing-card-${idx}`}
            />
          );
          let split;
          if (cardData?.split_name) {
            const splitName: string = cardData.split_name;
            split = (
              <SplitTreatments
                names={[splitName]}
                attributes={{ email: loggedInUser.emailAddress || "" }}
                key={`${idx}-split`}
              >
                {({ treatments, isReady }): JSX.Element | null => {
                  return isReady && treatments[splitName].treatment === "on"
                    ? cardElement
                    : null;
                }}
              </SplitTreatments>
            );
          } else {
            split = cardElement;
          }
          acc.push(split);
        }
        return acc;
      },
      []
    );
    return components || null;
  };

  return <>{renderCards()}</>;
};

export default MarketingCards;
