/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React from "react";
import { Button } from "@xometry/xometry_loft";
import { CTAWrapper, Copy, FlexPanel, ImgWrapper, StyledImg } from "./TeamspaceBanner.style";
import { track } from "../../utils/analytics";
import { origins, publicUrl } from "../../utils/constants";


const TeamspaceBanner = () => {
  const ctaText = "Create Your Teamspace"
  return (
    <FlexPanel kind="primary">
      <CTAWrapper>
        <Copy>Collaborate on Teamspace</Copy>
        <Button
          kind="primary"
          onClick={() => {
            track(ctaText);
            window.location.assign(`${origins.home}/account/organization/create?utm_content=psp_teamspace_banner2_cta`);
          }}
        >
          {ctaText}
        </Button>
      </CTAWrapper>
      <ImgWrapper>
        <StyledImg
          width="200"
          height="170"
          src={`${publicUrl}/assets/images/spot-team-discussion.svg`}
          alt="Teamspace Discussion"
        />
      </ImgWrapper>
    </FlexPanel>
  );
};

export default TeamspaceBanner;
