/* eslint-disable no-underscore-dangle */
import React, { Suspense } from "react";
import { jsx } from "@emotion/react";
/** @jsx jsx */
import { Spinner } from "@xometry/xometry_loft";
import {
  addImportDependencies,
  loadRemoteComponentWithRetries,
} from "../../utils/lazyLoad";
import { origins } from "../../utils/constants";

import QuoteUploadComponent, { QuoteUploadProps } from "./QuoteUploadComponent";
import XomErrorBoundary from "../XomErrorBoundary/XomErrorBoundary";

const LazyQuoteUploadComponent = React.lazy(() => {
  type Module = { default: typeof QuoteUploadComponent };
  return loadRemoteComponentWithRetries(
    `${origins.hosted}/quoting/quote/microcomponents/quote-upload.mjs`
  ) as Promise<Module>;
});

const QuoteUploadPanel = ({ children, ...props }: QuoteUploadProps) => {
  const isSSR = typeof window === "undefined";

  if (!isSSR) {
    addImportDependencies();
  }

  return (
    <Suspense fallback={<Spinner scale="full-screen" />}>
      <XomErrorBoundary>
        <LazyQuoteUploadComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {children}
        </LazyQuoteUploadComponent>
      </XomErrorBoundary>
    </Suspense>
  );
};

export default QuoteUploadPanel;
