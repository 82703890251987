import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

const wrapperDiv = css`

  /* Loading Skeleton Classes */
  .loading-block {
    display: block;
    background: ${theme.colors.darkBlues[10]};

    &.ld-headline {
      height: 40px;
      margin-bottom: 16px;
    }

    &.ld-image {
      height: 140px;
      width: 100%;
      margin-top: 14px;
    }

    &.ld-prop {
      height: 20px;
      margin-bottom: 4px;
    }

    &.ld-cta {
      height: 30px;
      margin-top: 16px;
    }
  }
`;

const buttonCss = css`
  width: 100%;
`;

const headerBar = css`
  color: #253842;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  img {
    height: 21px;
    width: 85px;
  }
`;

const imageWrapper = css`
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 16px;
  margin-top: 16px;

  img {
    max-height: 160px;
    margin: 0 auto;
    display: block;
    width: 100%;
    object-fit: contain;
  }
`;

const newBadge = css`
  background-color: #fcc351;
  border-radius: 12.5px;
  color: #253842;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  line-height: 21px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
  margin-top: 3px;
`;

const titleContent = css`
  display: inline-block;
`;

const headerTitle = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const headerSubtitle = css`
  font-size: 12px;
  line-height: 17px;
`;

const cardBody = css`
  color: #253842;
  font-size: 14px;
  line-height: 20px;
  ul {
    color: #253842;
    font-size: 14px;
    line-height: 20px;
    color: --lo-darker-gray;
    list-style: none;
    padding-left: 15px;
    li {
      margin-top: 4px;
      &::before {
        content: " ";
        background-color: #1491d0;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-bottom: 2px;
        margin-right: 8px;
        margin-left: -14px;
      }
    }
  }
`;

export {
  wrapperDiv,
  buttonCss,
  headerBar,
  imageWrapper,
  newBadge,
  headerTitle,
  cardBody,
  headerSubtitle,
  titleContent,
};
