import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";
import { themeOverrides } from "../../../utils/themeOverrides";

const wrapper = css`
  
`;

const componentNotLoadedWrapper = css`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  flex-flow: row;

  ${themeOverrides.mediaQueries.mobile} {
    flex-flow: column;
  }
`;

const componentNotLoaded = css`
  width: 100%;
  min-height: 203px;
  font-size: ${theme.fontSizes[0]};
  font-weight: ${theme.fontWeights.regular};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  &:first-child {
    margin-right: 20px;
  }

  p {
    display: block;
    margin: 3px auto;

    i {
      margin-right: 5px;
    }
  }
`;

const retryButton = css`
  color: ${theme.colors.blues[125]};
  font-size: ${theme.fontSizes[0]};
  font-weight: ${theme.fontWeights.regular};

  /* fix loft link-focus bug */
  &:focus {
    background: none !important;
    border: none !important;
  }
`;

export { wrapper, componentNotLoadedWrapper, componentNotLoaded, retryButton };
