import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

import { themeOverrides } from "../../utils/themeOverrides";

export const bannerBase = [
  css`
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      margin: 4px 0 0;
      font-size: 14px;
      font-weight: 400;
      a {
        font-weight: inherit;
      }
    }

    ${themeOverrides.mediaQueries.tablet} {
      flex-direction: column;
    }
  `,
];

export const buttonCss = css`
  padding: 4px 10px;
  white-space: nowrap;
  max-height: 48px;

  ${themeOverrides.mediaQueries.mobile} {
    margin-top: 12px;
    align-self: flex-start;
  }
`;

export const bodyTextWrapper = css`
  flex: 1;
  margin: auto 0;
  img {
    height: 50px;
    margin-right: 10px;
  }
  display: flex;
  > div:nth-child(1) {
    flex: 0;
    margin: 0;
  }
  > div:nth-child(2) {
    flex: 1;
    margin: auto;
  }
  ${themeOverrides.mediaQueries.smDesktop} {
    margin: 0 auto 0 0;
  }
`;

export const ctaButtonWapper = css`
  display: flex;
  button {
    margin-left: 24px;
  }
  ${themeOverrides.mediaQueries.smDesktop} {
    flex-direction: column;
    margin: 0 auto 0 0;
    button {
      margin: 10px 0 0;
    }
  }
  ${themeOverrides.mediaQueries.mobile} {
    width: 100%;
    button {
      width: 100%;
      margin: 10px 0 0 0;
    }
  }
`;

export const subtitle = css`
  color: ${theme.colors.grays[0]};
  font-size: 12px;
  font-weight: 600;
  padding: 0px 5px;
  margin-bottom: 16px;
`;

export default {
  bannerBase,
  buttonCss,
  ctaButtonWapper,
  bodyTextWrapper,
  subtitle,
};
