import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

export const image = css`
  width: 30vw;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;

  font-size: 32px; 
  gap: 20px;

  color: ${theme.colors.darkBlues[100]};
  img {
    width: 100%;
  }
`;

export const center = css`
  display: flex;
  justify-content: center;
`