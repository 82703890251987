import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";
import { themeOverrides } from "../../utils/themeOverrides";

export const wrapperDiv = [
  css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    font-size: ${theme.fontSizes[2]};
    line-height: ${theme.lineHeights[3]};
    margin-bottom: ${theme.space[8]};

    h3 {
      font-size: ${theme.fontSizes[3]};
      margin: 0 0 ${theme.space[1]} 0;
      color: ${theme.colors.darkBlues[100]};
      font-weight: ${theme.fontWeights.semibold};
      line-height: ${theme.lineHeights[4]};
    }

    button {
      margin: ${theme.space[4]} 0 0 0;
    }
  `,
];

export const bodyTextWrapper = css`
  flex: 2;
  margin: auto 0;
  color: ${theme.colors.darkBlues[90]};
  padding: ${theme.space[3]};

  img {
    height: 137px;
    margin: -${theme.space[1]} ${theme.space[5]} 0 -${theme.space[1]};
  }
  display: flex;
  > div:nth-of-type(1) {
    flex: 0;
    margin: 0;
  }
  > div:nth-of-type(2) {
    flex: 1;
    margin: auto;
  }
  ${themeOverrides.mediaQueries.smDesktop} {
    margin: 0 auto 0 0;
  }
  ${themeOverrides.mediaQueries.mobile} {
    flex-wrap: wrap;
  }
`;

export default {
  wrapperDiv,
  bodyTextWrapper,
};
