import styled from "@emotion/styled";
import { Flex, Panel, theme } from "@xometry/xometry_loft";

export const FlexPanel = styled(Panel)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: ${theme.breakpoints[0]}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const CTAWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${theme.breakpoints[0]}) {
    align-items: center;
  }
`;

export const Copy = styled.div`
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px;

  @media (max-width: ${theme.breakpoints[0]}) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
`;

export const ImgWrapper = styled.div`
  background-color: ${theme.colors.blues[10]};
  margin: -20px -20px -20px 8px;
  clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0% 100%);

  @media (max-width: ${theme.breakpoints[0]}) {
    background-color: initial;
    clip-path: initial;
    margin: initial;
    margin-bottom: 12px;
  }
`;

export const StyledImg = styled.img`
  margin: 20px 20px 20px 60px;

  @media (max-width: ${theme.breakpoints[0]}) {
    margin: initial;
  }
`;
