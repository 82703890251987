import React from "react";
import { publicUrl } from "../../utils/constants";
import {image} from "./FallbackComponent.style";

const FallbackComponent = () => {
    return (
    <div css={image}>
      <img
        src={`${publicUrl}/assets/images/broken-xom-logo.png`}
        alt="Broken Xometry Logo"
      />
      <span> Sorry!</span>
      <span>Something went wrong.</span>
    </div>
  )
}

export default FallbackComponent;