/* eslint-disable jsx-a11y/label-has-associated-control, camelcase */
// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { jsx } from "@emotion/react";
/** @jsx jsx */
import PrismicDom from "prismic-dom";
import { Panel } from "@xometry/xometry_loft";
import PersonalizedMarketingCard from "./PersonalizedMarketingCard";
import { fetchContent } from "../../utils/personalization";
import { PersonalizedStartPageMarketingCardsBodyPersonalizedStartPageMarketingCard as Card } from "../../schema/generated/prismic-schema";
import { wrapperDiv } from "./MarketingCard.style";
import { genericTrack } from "../../utils/analytics";

type User = {
  emailAddress: string | null;
  id: string | null;
};
interface Props {
  loggedInUser: User;
}

interface PersonalizedCardProps {
  content: Card;
  audience_name: string;
  touchpoint_id: string;
  card_id: string;
}

export const LoadingSkeletonCard = () => (
  <Panel kind="outlineGray" css={wrapperDiv}>
    <div className="loading-block ld-headline">&nbsp;</div>
    <div>
      <div className="loading-block ld-prop">&nbsp;</div>
      <div className="loading-block ld-prop">&nbsp;</div>
    </div>
    <div className="loading-block ld-image">&nbsp;</div>
    <div className="loading-block ld-cta">&nbsp;</div>
  </Panel>
);

const PersonalizedMarketingCards = ({ loggedInUser }: Props) => {
  const [cards, setCards] = useState<PersonalizedCardProps[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCardsContent = async () => {
      if (loggedInUser.id) {
        const touchpointIds = [0, 1];
        const cardPromises = touchpointIds.map(touchpointId => {
          return fetchContent(loggedInUser.id, touchpointId.toString());
        });
        await Promise.all(cardPromises)
          .then(cardsData => {
            cardsData.forEach(card => {
              genericTrack("Personalized Placement Viewed", card);
            });
            setCards([...cardsData]);
          })
          .catch(_ => {
            setError(true);
          });
      }
    };

    fetchCardsContent();
  }, [loggedInUser.id]); // Only update if the prismicClient changes (should only fetch data once on render)

  const renderCards = (): Array<JSX.Element> | JSX.Element | null => {
    if (error) {
      return <></>;
    }

    const components: Array<JSX.Element> | undefined = cards?.map(
      (card, idx: number) => {
        const cardData = card.content.primary;
        let props;
        if (cardData?.header_title && cardData?.card_body) {
          props = {
            headerTitle: cardData?.header_title,
            headerSubtitle: cardData?.header_subtitle,
            headerLinkText: cardData?.header_link_text,
            headerLinkUrl: cardData.header_link_url
              ? PrismicDom.Link.url(cardData?.header_link_url)
              : undefined,
            headerImage: cardData?.header_image
              ? cardData?.header_image
              : undefined,
            cardBody: cardData?.card_body
              ? PrismicDom.RichText.asHtml(cardData?.card_body)
              : undefined,
            bodyImage: cardData?.body_image,
            ctaText: cardData?.cta_text,
            ctaUrl: cardData?.cta_url
              ? PrismicDom.Link.url(cardData?.cta_url)
              : undefined,
            newLabel: cardData?.new_label,
          };
        }

        return props ? (
          <PersonalizedMarketingCard
            key={`marketing-card-${idx}`}
            content={props}
            audienceName={card.audience_name}
            cardId={card.card_id}
            touchpointId={card.touchpoint_id}
          />
        ) : (
          <></>
        );
      }
    );

    return components.length > 0 ? (
      components
    ) : (
      <>
        <LoadingSkeletonCard key="loading-skeleton-0" />
        <LoadingSkeletonCard key="loading-skeleton-1" />
      </>
    );
  };

  return <>{renderCards()}</>;
};

export default PersonalizedMarketingCards;
