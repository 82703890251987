/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { isValidElement } from "react";

import FallbackComponent from "./FallbackComponent";
import { center } from "./FallbackComponent.style";

type ErrorBoundaryState = {
  error: any;
  errorInfo: any;
};

type ErrorBoundaryProps = {
  fallbackUI?: React.ComponentType;
};
class XomErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Log errors to console for debugging
    console.error(this.state.error);
    console.error(this.state.errorInfo);
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { fallbackUI: FallbackComp } = this.props;

    if (this.state.errorInfo) {
      if (FallbackComp) {
        if (isValidElement(FallbackComp)) {
          return (
            <div className="unexpected-error" css={center}>
              {FallbackComp}
            </div>
          );
        }
        if (typeof FallbackComp === "function") {
          return (
            <div className="unexpected-error" css={center}>
              <FallbackComp />
            </div>
          );
        }
      }
      return (
        <div className="unexpected-error">
          <FallbackComponent />
        </div>
      );
    }
    return this.props.children;
  }
}

export default XomErrorBoundary;
