/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line
import React, { useContext } from "react";
import { Panel, Link, Button } from "@xometry/xometry_loft";
import { jsx } from "@emotion/react";
/** @jsx jsx */
import { genericTrack } from "../../utils/analytics";

import {
  wrapperDiv,
  buttonCss,
  headerBar,
  imageWrapper,
  newBadge,
  headerTitle as headerTitleCss,
  headerSubtitle as headerSubtitleCss,
  cardBody as cardBodyCss,
  titleContent,
} from "./MarketingCard.style";

type Maybe<T> = T | null;

interface PrismicContent {
  headerTitle: string;
  headerSubtitle?: Maybe<string>;
  newLabel: boolean;
  headerLinkText?: Maybe<string>;
  headerImage?: {
    url: Maybe<string>;
    alt?: Maybe<string>;
  };
  headerLinkUrl?: Maybe<string>;
  cardBody?: Maybe<string>;
  bodyImage?: {
    url?: Maybe<string>;
    alt?: Maybe<string>;
  };
  ctaText?: Maybe<string>;
  ctaUrl?: Maybe<string>;
}

interface HeaderLinkContentProps {
  headerImage?: {
    url: Maybe<string>;
    alt?: Maybe<string>;
  };
  headerLinkText?: Maybe<string>;
}

const HeaderLinkContent: React.FC<HeaderLinkContentProps> = (
  props: HeaderLinkContentProps
) => {
  const { headerImage, headerLinkText } = props;
  if (headerImage?.url) {
    return <img src={headerImage.url} alt={headerImage.alt || "header"} />;
  }
  if (headerLinkText) {
    return <div>{headerLinkText}</div>;
  }
  return null;
};

const HeaderLink: React.FC<PersonalizedMarketingCardProps> = (
  props: PersonalizedMarketingCardProps
) => {
  const { content } = props;
  const { headerImage, headerLinkText, headerLinkUrl } = content;

  if (!headerImage && !headerLinkText) {
    return null;
  }

  const eventName = "Personalized Placement Header Link Clicked";

  if (headerLinkUrl) {
    return (
      <div>
        <Link
          onClick={(): boolean => {
            genericTrack(eventName, props);
            return true;
          }}
          target="_blank"
          href={headerLinkUrl}
        >
          {HeaderLinkContent({ headerImage, headerLinkText })}
        </Link>
      </div>
    );
  }
  return <div>{HeaderLinkContent({ headerImage, headerLinkText })}</div>;
};

const CTAButton: React.FC<PersonalizedMarketingCardProps> = (
  props: PersonalizedMarketingCardProps
) => {
  const { content } = props;
  const { ctaText, ctaUrl } = content;

  const eventName = "Personalized Placement CTA Clicked";

  if (!ctaText || !ctaUrl) return null;

  return (
    <Button
      data-testid="cta_button"
      css={buttonCss}
      onClick={(): boolean => {
        genericTrack(eventName, props);
        window.location.href = ctaUrl;
        return true;
      }}
    >
      {ctaText}
    </Button>
  );
};

interface PersonalizedMarketingCardProps {
  /* eslint-disable react/no-unused-prop-types */
  // Disable es-lint warning as it is a false positive as seen https://bit.ly/3dNY2Ay
  content: PrismicContent;
  touchpointId: string;
  audienceName: string;
  cardId: string;
}

const PersonalizedMarketingCard: React.FC<PersonalizedMarketingCardProps> = (
  props: PersonalizedMarketingCardProps
) => {
  const {
    content: { newLabel, headerTitle, headerSubtitle, bodyImage, cardBody },
  } = props;
  return (
    <Panel kind="outlineWhite" css={wrapperDiv}>
      <div css={headerBar}>
        <div>
          {newLabel && <div css={newBadge}>New</div>}
          <div css={titleContent}>
            <div css={headerTitleCss}>{headerTitle}</div>
            <div css={headerSubtitleCss}>{headerSubtitle}</div>
          </div>
        </div>
        {HeaderLink(props)}
      </div>
      <div
        css={cardBodyCss}
        // Use dangerously set inner html as this is a rich text field with html formatting.
        // We have complete control over the content in Prismic, and it's being rendered using Prismic's own
        // library which (presumably) also does sanitization. So this *should* be perfectly safe.
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: cardBody || "" }}
      />
      {bodyImage?.url && (
        <div css={imageWrapper}>
          <img src={bodyImage.url} alt={bodyImage.alt || "body"} />
        </div>
      )}
      {CTAButton(props)}
    </Panel>
  );
};

export default PersonalizedMarketingCard;
