import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";
import { panelBase } from "../../index.style";
import { themeOverrides } from "../../utils/themeOverrides";

export const wrapperDiv = ({ isPunchout = false }) => [
  panelBase,
  css`
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
    background: transparent;
    text-align: center;
    color: ${theme.colors.grays[1]};
    line-height: 1.5;
    .panelNavArea {
      display: flex;
      justify-content: space-between;
      min-height: 67px;

      button {
        margin-top: 16px;
      }

      > div {
        flex-grow: 1;
        flex-basis: 0;

        &:first-of-type {
          text-align: left;
          padding-top: 8px;
        }
        &:last-child {
          text-align: right;
          padding-top: 8px;
        }
      }
    }

    .hideButton {
      display: none;
    }

    &.half-width {
      .uploadPanel {
        .uploadTreatment {
          .exampleParts {
            img {
              height: 68px;
              margin: 37px 0 0 25px;

              &:first-of-type {
                margin-left: 0;
              }
            }
          }

          h2 {
            font-size: 16px;
            sup {
              font-size: 8px;
            }
          }

          h3 {
            font-size: 13px;
            font-weight: normal;
            max-width: 275px;
            margin: 0 auto;
          }

          .supportedFilesList {
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }
    .uploadPanel {
      box-sizing: border-box;
      cursor: pointer;
      min-height: 160px;
      border: 1px dashed #1491d0;
      padding: 20px;
      background-color: rgba(20, 145, 208, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.splitFileUpload {
        margin: -20px -20px 0 -20px;
        border: 1px dashed ${theme.colors.brandBlue};
        background: rgb(250, 252, 254);
        background: linear-gradient(0deg, #e6f0fd 0%, #ffffff 100%);

        :hover {
          background: rgb(243, 248, 254);
          background: linear-gradient(0deg, #dbe9fd 0%, #fafcff 100%);
        }
      }

      .uploadDragDrop {
        font-size: 16px;
        font-weight: ${theme.fontWeights.semibold};
        margin-bottom: 5px;
        margin-top: 0;

        button {
          margin: 0;
        }
      }

      .supportedFilesText {
        font-size: 14px;
        font-weight: ${theme.fontWeights.semibold};
      }

      .fileIcons {
        margin-top: 8px;
        display: flex;
        padding: 0px 12px;
        justify-content: center;
        img {
          height: 16px;

          &.siemens {
            height: 13px;
            padding-top: 2px;
          }

          &:nth-child(n + 2) {
            margin-left: 12px;
          }
        }
      }

      button {
        margin: 0 0 8px 12px;
      }

      // hack: override the other importants here:
      #uploadButton {
        margin-top: 5px !important;
      }

      .file-list-prompt {
        margin-top: 16px;
      }

      .file-list-wrapper {
        max-height: 340px;
        overflow-y: scroll;
      }

      .uploadTreatment {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 64px 20px 0px;

        color: ${theme.colors.darkBlues[100]};
        margin: -20px;
        padding: 0 16px;

        .uploadContents {
          display: flex;
          flex-direction: row;
        }

        .spotDownload {
          ${isPunchout ? "display: none;" : "display: flex;"}
          flex-direction: column;
          justify-content: center;
          width: 106px;

          ${themeOverrides.mediaQueries.mobile} {
            display: none;
          }

          ${themeOverrides.mediaQueries.tablet} {
            display: none;
          }

          div {
            display: flex;
            flex-direction: row;
            margin-right: 44px;

            img {
              width: 62px;
              height: 83px;
            }
          }
        }

        .uploadButtons {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }

        .exampleParts {
          img {
            height: 110px;
            margin: 40px 0 0 40px;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }

        h2 {
          font-size: ${theme.fontSizes[4]};
          font-weight: ${theme.fontWeights.semibold};
          margin: 20px 16px 8px 16px;

          sup {
            font-size: ${theme.fontSizes[0]};
          }
        }

        h3 {
          font-size: ${theme.fontSizes[3]};
          font-weight: ${theme.fontWeights.regular};
          color: ${theme.colors.darkBlues[90]};
          margin: 8px 0;
        }

        .supportedFilesList {
          font-size: ${theme.fontSizes[1]};
          font-weight: ${theme.fontWeights.semibold};
          color: ${theme.colors.darkBlues[50]};
          text-align: center;
          line-height: ${theme.lineHeights[3]};
          margin-top: 12px;
        }
      }
    }
  `,
];

export const fileRow = css`
  text-align: left;
  display: block;
  margin: 0 auto;
  height: 53px;
  border: 1px solid #d8dee1;
  border-radius: 2px;
  background-color: #ffffff;

  &:hover {
    cursor: default;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:not(:last-child) {
    margin-bottom: 7.5px;
  }

  &:focus {
    outline: none;
  }

  .fileRowContent {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 13px;

    img {
      width: 14px;
      height: 14px;
      display: inline-block;
      margin-left: 3.5px;
      margin-right: 12px;
      vertical-align: middle;
    }

    span {
      color: ${theme.colors.grays[1]};
      font-size: 14px;
      line-height: 20px;
    }

    .fileInfoContainer {
      display: inline-flex;
      flex-flow: row wrap;
      flex-direction: row;
      max-width: calc(100% - 85px);
      align-items: center;
      padding-top: 2px;

      .fileNameWrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .fileErrorMessage {
        display: inline-block;
        width: 100%;
        font-size: 0.75rem;
      }
    }

    i {
      color: #1979c3;
      font-size: 18px;
      font-weight: 300;
      line-height: 15px;
      top: 18px;
      right: 44px;
      position: absolute;
      cursor: pointer;
      span {
        display: none;
      }
    }
  }

  &.rejected {
    background: #f5ebeb;
    border-color: #9e3c3c;
    height: 60px;

    .fileRowContent {
      span,
      i {
        color: #9e3c3c;
      }
    }

    .fileErrorMessage {
      font-size: 10px;
      font-style: italic;
    }
  }
`;

export const btnGroup = css`
  button {
    margin-top: 16px;
  }

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const btnGroupAlt = css`
  button {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  button:not(:last-child) {
    margin-right: 8px !important;
    margin-left: 0 !important;
  }
`;
