import React from "react";
import { Button } from "@xometry/xometry_loft";
import { jsx } from "@emotion/react";
/** @jsx jsx */
import { wrapperDiv } from "./QuoteWizard.style";
import { genericTrack, track } from "../../utils/analytics";
import {
  chooseFileButtonCss,
  instantQuoteHeading,
  moreInfoText,
  quoteUploadSeparator,
  quoteUploadWrapper,
  quoteWizardText,
  quoteWizardWithRQContainer,
  quotingRow,
  requestQuoteContainer,
  requestQuoteText,
  rfqWizardWrapper,
} from "./QuoteWizardWithRQ.style";
import { openCustomerRfqModal } from "./CustomerRFQ";
import { publicUrl } from "../../utils/constants";
import QuoteUploadPanel from "../QuoteUploadPanel/QuoteUploadPanel";

export const PunchOutQuoteWizard = () => {
  return (
    <div css={wrapperDiv({ isPunchout: true })} className="half-width">
      <QuoteUploadPanel panelStyle="whiteSolid" showPartLibraryButton>
        <div className="uploadContents">
          <div className="spotDownload">
            <div>
              <img
                src={`${publicUrl}/assets/images/download-to-folder.png`}
                alt="Download To Folder"
              />
            </div>
          </div>
          <div className="uploadButtons">
            <div className="uploadDragDrop">
              Drag and Drop your file(s) or
              <Button
                css={chooseFileButtonCss}
                onClick={() => {
                  track("Upload 3D Models");
                }}
              >
                browse
              </Button>
            </div>
          </div>
        </div>
      </QuoteUploadPanel>
    </div>
  );
};

export const QuoteWizardWithRequestQuote = () => {
  return (
    <div css={rfqWizardWrapper}>
      <div css={quotingRow}>
        <div css={quoteUploadWrapper}>
          <QuoteUploadPanel
            panelStyle="blueSolid"
            showPartLibraryButton
            panelScale="large"
            instantQuoteButtonContent={<>Start a New Instant Quote</>}
            partLibraryButtonContent={<>Recent Uploads</>}
            showSupportedFileTypes
          >
            <div css={quoteWizardWithRQContainer}>
              <span css={instantQuoteHeading}>Instant Quote</span>
              <img
                src={`${publicUrl}/assets/images/download-to-folder.png`}
                alt="Download To Folder"
              />
              <div css={quoteWizardText}>
                Drag and Drop or
                <Button
                  css={chooseFileButtonCss}
                  onClick={() => {
                    track("Upload 3D Models");
                  }}
                >
                  Choose File
                </Button>
              </div>
            </div>
          </QuoteUploadPanel>
        </div>
        <div css={quoteUploadSeparator}>Or</div>
        <div
          css={requestQuoteContainer}
          tabIndex={0}
          role="button"
          onKeyDown={() => false}
          onClick={() => {
            openCustomerRfqModal();
            genericTrack("Customer RFQ CTA Clicked", {});
          }}
        >
          <b className="rfq-header">Quote Assemblies and Production Orders</b>
          <i className="far fa-cloud-upload" />
          <Button kind="primary-outline">Start Your Large Order</Button>
          <span css={requestQuoteText}>
            Upload BOM, SOW, design files, drawings, PDFs, and any other project
            files for a comprehensive quote prepared by Xometry experts.
          </span>
        </div>
        <div id="rfqContainer" />
      </div>
      <span id="customer_rfq_appcues_target" css={moreInfoText} />
    </div>
  );
};
