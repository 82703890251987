/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React from "react";
import { Panel, Button } from "@xometry/xometry_loft";
import { jsx } from "@emotion/react";
import { bodyTextWrapper, wrapperDiv } from "./QuoteWithoutCADPanel.style";
import { genericTrack } from "../../utils/analytics";
import { publicUrl } from "../../utils/constants";
import { openCustomerRfqModal } from "../QuoteWizard/CustomerRFQ";
/** @jsx jsx */

type User = {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
};
interface Props {
  loggedInUser?: User;
}

const br = "%0D%0A";

const renderQuestionnaire = () => {
  const bullet = " %E2%80%A2 ";
  const questions = [
    `What manufacturing process, material, and finish do you expect for these parts?`,
    `How many of each part do you need?`,
    `What is the most important aspect of your project?${br}   Pricing, lead time, or a combination of both`,
    `Do you have a preference for a manufacturing location?${br}   USA, China/Asia, or either`,
    `What is the latest possible date to receive your initial shipment of custom parts?`,
    `If your project is a higher quantity order, are partial shipments an option?`,
    `Is this a one-time order, or do you expect to order additional quantities of these parts in the future?`,
    `Are you open to receiving design feedback that may reduce your cost/lead time?`,
    `Do you have a particular manufacturing approach in mind for your design?`,
    `What price for these parts would make you want to work with us?`,
    `Is there anything else you would like us to know about your project as we begin quoting your design?`,
  ];
  let questionnaire = "";
  // prepend each question with bullet point
  questions.forEach(question => {
    questionnaire += `${br}${br}${bullet} ${question}`;
  });
  return questionnaire;
};

const QuoteWithoutCADPanel = ({
  loggedInUser,
}: Props) => {
  const { id, firstName, lastName, emailAddress } = loggedInUser || {};
  const email = "getaquote@xometry.com";
  const subjectText = `Request for Quote ${loggedInUser ? `- ${id}` : null}`;
  // Body text has horrible formatting because whitespace is picked up and used in the html block
  const bodyText = `
    ${loggedInUser
      ? `${br}${firstName},`
      : null}
${br}Please be sure to attach all files that describe your project, answer the questions below, and include any additional information about your project before sending this email. Thanks!
${br}${br}---------------------------------------
${br}${br}
Hello Xometry team, please see the attached files and questionnaire responses for more context about my project.
    ${loggedInUser
      ? `${br}${br}${firstName} ${lastName}
${br}${id}
${br}${emailAddress}
${renderQuestionnaire()}
${br}${br}${br}Thanks!`
      : null
    }
  `;

  return (
    <Panel kind="primary" css={wrapperDiv}>
      <div css={bodyTextWrapper}>
        <div>
          <img
            alt="2d print drawing"
            src={`${publicUrl}/assets/images/print-drawing-2x.png`}
          />
        </div>
        <div style={{minWidth: "239px"}}>
          <h3>No 3D model? We also quote technical drawings.</h3>
          Our in-house engineers can provide you with a quote on technical
          drawings in as fast as 1 business day.
          <br />
          <Button
            kind="outline-blue"
            scale="large"
            onClick={() => {
              genericTrack("CADless Quote Requested", { location: ["PSP"] });
              openCustomerRfqModal();
            }}
          >
            Request a Quote
          </Button>
        </div>
      </div>
    </Panel>
  );
};

export default QuoteWithoutCADPanel;
