// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/react";
/** @jsx jsx */
import { SplitTreatments, useManager } from "@splitsoftware/splitio-react";
import { sortBy } from "lodash";
import NotificationBanner from "./NotificationBanner";
import { subtitle } from "./NotificationBanner.style";

export const SplitIOConfigurable = (props: { propsKey: string }) => {
  const { propsKey } = props;
  const manager = useManager();
  if (!manager) {
    return null;
  }
  const allFeatures = manager.names().filter(name => name.startsWith("psp"));

  return (
    <SplitTreatments names={allFeatures}>
      {({ treatments, isReady }) => {
        if (!isReady) {
          return null;
        }
        const bannersConfig = allFeatures
          .reduce<
            {
              featureName: string;
              treatment: string;
              config: any;
            }[]
          >((memo, featureName) => {
            const { treatment, config } = treatments[featureName] || {};
            if (
              !treatment ||
              !config ||
              ["off", "control"].includes(treatment)
            ) {
              return memo;
            }
            try {
              return memo.concat({
                featureName,
                treatment,
                config: JSON.parse(config),
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err);
              // according to the splitio docs feature.config
              // is either null or stringified json...
              // so this catch case should never happen
              return memo;
            }
          }, [])
          .filter(({ config }) => config[propsKey]);

        const bannerInstances = sortBy(
          bannersConfig,
          ({ config }) => config.pspRank || 99
        ).map(({ treatment, config, featureName }) => {
          const name = `${featureName}_${treatment}_${propsKey}`;
          return (
            // eslint-disable-next-line
            <NotificationBanner {...config[propsKey]} key={name} name={name} />
          );
        });

        return (
          <>
            {!!bannerInstances?.length && (
              <div css={subtitle}>Discover Something New</div>
            )}
            {bannerInstances}
          </>
        );
      }}
    </SplitTreatments>
  );
};

export default {
  SplitIOConfigurable,
};
